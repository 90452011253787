export interface META_CONFIG_EMAIL {
  notify: boolean;
  events?: string[];
  emailAddress?: string;
}
export interface META_CONFIG {
  email: META_CONFIG_EMAIL;
  consumer_roles: string[];
}

export interface SUMMARY {
  text: string;
  regex: string;
}
export interface WORK_ITEM_PARAMETERS {
  summary: SUMMARY;
  product: string;
  executor: string;
  metaConfig: META_CONFIG;
  contentIdArray: number[];
  parameters: any;
  target_nodes?: string;
}

export interface STEP_ITEM {
  name: string;
  labelPrefix?: string[];
  labelSurffix?: string;
  type: STEP_TYPE;
  show: boolean;
  valueName?: string;
  valuesArray?: any[];
  valuesDisabled?: boolean[];
  hasNextStepButton: boolean;
  showNextStepButton?: boolean;
  loading?: boolean;
  icon?: string;
  color?: string;
  disabled?: boolean;
  placeholder?: string;
}
export interface STEP_OPTION_ITEM_CONFIG {
  label?: string;
  description?: string;
  type?: string;
  value?: any;
  values?: any;
  addTag?: boolean;
  hidden?: string;
  inputType?: string;
  multivalued?: boolean;
  disabled?: any;
  fileList?: any;
  pickerOptions?: any;
  isDate?: string;
  datetype?: string;
  dateFormat?: any;
  timeZone?: any;
  isLoading?: boolean;
  props?: any;
  showAllLevels?: boolean;
  _name?: string;
  _type?: any;
  _value?: any;
  _values?: any;
  _regex?: string;
  _valuesUrl?: any;
  _required?: string;
  _secure?: string;
  _valueExposed?: string;
  _enforcedvalues?: any;
  _delimiter?: any;
  _valuesListDelimiter?: any;
  _storagePath?: string;
  _allowedResourceTypes?: string;
  _defaultResourceType?: string;
  _allowedResourceNum?: string;
  [x: string]: any;
}

export interface CUSTOMER_RESOURCE_INFO {
  resourceName: string;
  resourceGroupName: string;
  resourceType: string;
  customerCode: string;
  customerName: string;
  projectCode: string;
  tenantId: string;
  subscriptionId: string;
  isActive: string;
}

export interface SCHEDULE_FORM_TEMP_DATA {
  startTime: string | number;
}

export interface SCHEDULE_FORM_DATA {
  startTime?: string | number;
  cronExpression?: string;
}

export interface SUBMIT_SCHEDULE_WORKITEM_OBJECT {
  cx_iops_job_schedule: any;
}

export interface SUBMIT_WORKITEM_OBJECT {
  owner: string;
  created_by: string;
  summary: string;
  product: string;
  executor: string;
  meta_config: string;
  parameters: string;
  app_name: string;
  hybris_workspace_item_jobs: any;
  schedule_type: SCHEDULE_TYPE;
  job_type: WORKITEM_JOB_TYPE;
  schedule_id?: string;
  cx_iops_job_schedule?: any;
  cx_iops_agent_job_executions?: any;
}

export interface SUBMIT_DATA_FROM_SCHEDULE_POPUP {
  scheduleType: SCHEDULE_TYPE;
  formData: SCHEDULE_FORM_DATA;
}

export interface GET_ALL_JOB_PARAMS {
  rundeckName: string[];
}

export interface GET_CCV1_ALL_NODES_PARAMS {
  rundeckName: string | undefined;
}

export interface GET_JOB_DEFINITION_PARAMS {
  id: any;
  jobName: any;
  jobRunDeckId: any;
  runDeckName: string;
  product: string;
}

export interface RUN_CASE_API_PARAMS {
  scheduleType: SCHEDULE_TYPE;
  id: string | number;
  name: string;
}

export enum CLOUD_PRODUCT_TYPE {
  commerce = "Commerce",
  c4c = "C4C",
}

export const CLOUD_PRODUCTS = Object.freeze(["Commerce", "C4C"]);

export enum STEP_STATUS {
  inProgress = "inProgress",
  succeed = "succeed",
  failed = "failed",
  disabled = "disabled",
  loading = "loading",
}

export enum OPTION_BUSINESS_TYPE {
  General = "General",
  Commerce = "Commerce-specific",
}

export enum JOB_OPTION_TYPE {
  select = "select",
  multiselect = "multiselect",
  tag = "tag",
  input = "input",
  secure = "secure",
  file = "file",
  date = "date",
  commerce = "commerce-specific",
  cascader = "commerce-specific-cascader",
}

export enum PRODUCT_NAME {
  ccv1 = "CCV1",
  ccv2 = "CCV2",
  scv2 = "SCV2",
}

export enum STEP_TYPE {
  card = "step_card",
  cascader = "step_cascader",
  input = "step_input",
  select = "step_select",
  form = "step_form",
  button = "step_button",
  metaForm = "step_meta_form",
}

export enum COMMERCE_OPTION_ITEMS_DESCRIPTION {
  ccv1Nodes = "[ Commerce-specific ] all nodes in CCV1",
  ccv2DBs = "[ Commerce-specific ] all databases in CCV2",
  ccv2DBMini = "[ Commerce-specific ] CCV2 database Minimum option",
  dtuTier = "[ Commerce-specific ] DTU Tier option",
  dtuTierMini = "[ Commerce-specific ] DTU Minimum option",
  futureDate = "[ Commerce-specific ] date:future time selectable",
  datewithTz = "[ Commerce-specific ] date with timezone",
  dateRangewithTz = "[ Commerce-specific ] date-range with timezone",
  subscriptions = "[ Commerce-specific ] Customer Subscriptions",
  customerResources = "[ Commerce-specific ] CCV2 Customer Resources",
  customerResourcesNames = "[ Commerce-specific ] CCV2 Customer Resources Names",
}

export enum WORKITEM_JOB_TYPE {
  ondemand = "Ondemand",
  scheduled = "Scheduled",
}

export enum SCHEDULE_TYPE {
  once = "Once",
  periodically = "Periodically",
}
