import store from "@/store";
import { Ability, subject } from "@casl/ability";
import {
  PRODUCT_NAME,
  CLOUD_PRODUCTS,
  CLOUD_PRODUCT_TYPE,
} from "../interface/newItem";
export const actionsEnum = Object.freeze({
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
  RUN: "run",
  RELEASE: "release",
});

export const subjectsEnum = Object.freeze({
  CASE: "case",
  PRIMESERVICE: "PrimeService",
  APPLICATION: "Application",
  CLOUDPRODUCT: "Cloud Product",
  CONTENT: "Content",
  CONTENTGROUP: "ContentGroup",
  ALL: "all",
});

export const Auth = {
  createAbilityObject(): Ability {
    const decodedToken: any = JSON.parse(
      <string>localStorage.getItem("auth_jwt_decoded")
    );
    const abilityObject = new Ability(decodedToken.rules);
    return abilityObject;
  },

  //function to check if user is authorized to run myworkspace.
  isUserAuthorized(appList): boolean {
    const abilityObject = this.createAbilityObject();
    const categoryList: string[] = appList.map((app) => app.label);
    return categoryList.some((category) =>
      this.canUserRunAppCategory(abilityObject, category)
    );
  },
  //function to get authorized applist for an user role
  getAuthorizedAppList(appList): any {
    const abilityObject: Ability<any> = this.createAbilityObject();
    appList = appList.filter((app) => {
      if (this.canUserRunAppCategory(abilityObject, app.label)) return app;
    });
    return appList;
  },

  canUserRunAppCategory(
    abilityObject: Ability<any>,
    category: string
  ): boolean {
    return abilityObject.can(
      actionsEnum.RUN,
      subject(subjectsEnum.APPLICATION, {
        app: "MyWorkSpace",
        category: category,
      })
    );
  },

  canUserAccessCloudproduct(productName: string): boolean {
    const abilityObject: Ability<any> = this.createAbilityObject();
    return abilityObject.can(
      actionsEnum.READ,
      subject(subjectsEnum.CLOUDPRODUCT, {
        product: productName,
        category: store.state.currentApp.label,
      })
    );
  },

  getProductFilters(): { text: PRODUCT_NAME; value: PRODUCT_NAME }[] {
    const authorizedCloudproducts: {
      text: PRODUCT_NAME;
      value: PRODUCT_NAME;
    }[] = [];
    CLOUD_PRODUCTS.forEach((cloudProduct) => {
      if (
        cloudProduct === CLOUD_PRODUCT_TYPE.commerce &&
        Auth.canUserAccessCloudproduct(cloudProduct)
      ) {
        authorizedCloudproducts.push({
          text: PRODUCT_NAME.ccv2,
          value: PRODUCT_NAME.ccv2,
        });
      } else if (
        cloudProduct === CLOUD_PRODUCT_TYPE.c4c &&
        Auth.canUserAccessCloudproduct(cloudProduct)
      ) {
        authorizedCloudproducts.push({
          text: PRODUCT_NAME.scv2,
          value: PRODUCT_NAME.scv2,
        });
      }
    });
    return authorizedCloudproducts;
  },

  addAuthParamstoRequest(action: string, subject: string): any {
    return {
      authenticate: {
        action,
        subject,
        props: { app: "MyWorkSpace", category: store.state.currentApp?.label },
      },
    };
  },
};
