import gql from "graphql-tag";

// perf: as one workitem can execute thousands times in periodically scheduled,
// it's necessary to limit cx_iops_agent_job_executions return only 1 record to optimization
// network traffic boundwidth

const FRAGMENT_SCHEDULED_WORK_ITEM = gql`
  fragment fragment_scheduled_work_item on hybris_workspace_item {
    id
    summary
    product
    created_time
    owner
    job_type
    schedule_type
    schedule_id
    cx_iops_agent_job_executions {
      execution_id
      execution_status
    }
    hybris_workspace_item_jobs {
      job_id
      hybris_nb_cio_job {
        job_name
        is_private
      }
    }
  }
`;

const FRAGMENT_WORK_ITEM = gql`
  fragment fragment_work_item on hybris_workspace_item {
    id
    summary
    product
    created_time
    owner
    job_type
    schedule_type
    schedule_id
    hybris_workspace_item_jobs {
      job_id
      hybris_nb_cio_job {
        job_name
        is_private
      }
    }
    cx_iops_agent_job_executions(
      where: {
        _or: [
          { execution_status: { _is_null: true } }
          { execution_status: { _in: $execution_status } }
        ]
      }
      limit: 1
    ) {
      execution_status
      execution_id
      meta_data
      file_path
    }
  }
`;

export const GET_WORK_ITEMS_WITH_REPORT = gql`
  query getWorkItemList(
    $app_name: String
    $summary: String
    $owner: [String!]
    $product: [String!]
    $pageSize: Int!
    $offset: Int!
    $JobType: [String!]!
    $JobId: [bigint!]
    $execution_status: [String!]
    $createdTimeRangeFrom: timestamptz
    $createdTimeRangeTo: timestamptz
  ) {
    listByPage: hybris_workspace_item(
      where: {
        summary: { _ilike: $summary, _neq: "cleanup_containerized_job" }
        owner: { _in: $owner }
        product: { _in: $product }
        app_name: { _eq: $app_name }
        _and: [
          {
            _or: [
              { _not: { cx_iops_agent_job_executions: {} } }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _is_null: true }
                }
              }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _in: $execution_status }
                }
              }
            ]
          }
          {
            _or: [
              { job_type: { _in: $JobType } }
              { job_type: { _is_null: true } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
        cx_iops_agent_job_executions: {
          _and: [
            {
              _or: [
                { file_path: { _is_null: false } }
                { file_path: { _eq: "" } }
              ]
            }
            {
              _or: [
                { file_path: { _is_null: true } }
                { file_path: { _like: "%https%" } }
              ]
            }
          ]
        }
      }
      offset: $offset
      limit: $pageSize
    ) {
      ...fragment_work_item
      __typename
    }
    listTotal: hybris_workspace_item_aggregate(
      where: {
        summary: { _ilike: $summary, _neq: "cleanup_containerized_job" }
        owner: { _in: $owner }
        product: { _in: $product }
        app_name: { _eq: $app_name }
        _and: [
          {
            _or: [
              { _not: { cx_iops_agent_job_executions: {} } }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _is_null: true }
                }
              }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _in: $execution_status }
                }
              }
            ]
          }
          {
            _or: [
              { job_type: { _in: $JobType } }
              { job_type: { _is_null: true } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
        cx_iops_agent_job_executions: {
          _and: [
            {
              _or: [
                { file_path: { _is_null: false } }
                { file_path: { _eq: "" } }
              ]
            }
            {
              _or: [
                { file_path: { _is_null: true } }
                { file_path: { _like: "%https%" } }
              ]
            }
          ]
        }
      }
    ) {
      aggregate {
        count
        __typename
      }
      __typename
    }
  }
  ${FRAGMENT_WORK_ITEM}
`;

export const GET_WORK_ITEMS = gql`
  query getWorkItemList(
    $app_name: String
    $summary: String
    $owner: [String!]
    $product: [String!]
    $pageSize: Int!
    $offset: Int!
    $JobType: [String!]!
    $JobId: [bigint!]
    $execution_status: [String!]
    $createdTimeRangeFrom: timestamptz
    $createdTimeRangeTo: timestamptz
  ) {
    listByPage: hybris_workspace_item(
      where: {
        app_name: { _eq: $app_name }
        summary: { _ilike: $summary, _neq: "cleanup_containerized_job" }
        owner: { _in: $owner }
        product: { _in: $product }
        created_time: { _gte: $createdTimeRangeFrom, _lte: $createdTimeRangeTo }
        _and: [
          {
            _or: [
              { _not: { cx_iops_agent_job_executions: {} } }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _is_null: true }
                }
              }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _in: $execution_status }
                }
              }
            ]
          }
          {
            _or: [
              { job_type: { _in: $JobType } }
              { job_type: { _is_null: true } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
      }
      offset: $offset
      limit: $pageSize
      order_by: { created_time: desc }
    ) {
      ...fragment_work_item
    }
    listTotal: hybris_workspace_item_aggregate(
      where: {
        app_name: { _eq: $app_name }
        summary: { _ilike: $summary, _neq: "cleanup_containerized_job" }
        owner: { _in: $owner }
        product: { _in: $product }
        created_time: { _gte: $createdTimeRangeFrom, _lte: $createdTimeRangeTo }
        _and: [
          {
            _or: [
              { _not: { cx_iops_agent_job_executions: {} } }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _is_null: true }
                }
              }
              {
                cx_iops_agent_job_executions: {
                  execution_status: { _in: $execution_status }
                }
              }
            ]
          }
          {
            _or: [
              { job_type: { _in: $JobType } }
              { job_type: { _is_null: true } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
  ${FRAGMENT_WORK_ITEM}
`;

// get scheduled success work item in work item page
export const GET_SCHEDULE_WORK_ITEMS = gql`
  query getScheduledWorkItemList(
    $app_name: String
    $summary: String
    $owner: [String!]
    $product: [String!]
    $execution_status: [String!]
    $pageSize: Int!
    $offset: Int!
    $JobType: [String!]!
    $JobId: [bigint!]
    $ScheduledSuccess: String
    $Scheduled_Once: String!
    $Scheduled_Periodically: String!
    $createdTimeRangeFrom: timestamptz
    $createdTimeRangeTo: timestamptz
  ) {
    listByPage: hybris_workspace_item(
      where: {
        app_name: { _eq: $app_name }
        summary: { _ilike: $summary }
        owner: { _in: $owner }
        product: { _in: $product }
        job_type: { _in: $JobType }
        _or: [
          {
            _and: [
              { schedule_type: { _eq: $Scheduled_Periodically } }
              {
                cx_iops_job_schedule_periodics: {
                  status: { _eq: $ScheduledSuccess }
                }
              }
            ]
          }
          {
            _and: [
              { schedule_type: { _eq: $Scheduled_Once } }
              { cx_iops_job_schedule: { status: { _eq: $ScheduledSuccess } } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
        cx_iops_agent_job_executions: {
          run_start_time: {
            _gte: $createdTimeRangeFrom
            _lte: $createdTimeRangeTo
          }
          execution_id: { _is_null: false }
        }
      }

      offset: $offset
      limit: $pageSize
      order_by: { created_time: desc }
    ) {
      ...fragment_scheduled_work_item
    }
    listTotal: hybris_workspace_item_aggregate(
      where: {
        app_name: { _eq: $app_name }
        summary: { _ilike: $summary }
        owner: { _in: $owner }
        product: { _in: $product }
        job_type: { _in: $JobType }
        _or: [
          {
            _and: [
              { schedule_type: { _eq: $Scheduled_Periodically } }
              { cx_iops_job_schedule_periodics: {} }
              {
                cx_iops_job_schedule_periodics: {
                  status: { _eq: $ScheduledSuccess }
                }
              }
            ]
          }
          {
            _and: [
              { schedule_type: { _eq: $Scheduled_Once } }
              { cx_iops_job_schedule: { status: { _eq: $ScheduledSuccess } } }
            ]
          }
        ]
        hybris_workspace_item_jobs: { job_id: { _in: $JobId } }
        cx_iops_agent_job_executions: {
          run_start_time: {
            _gte: $createdTimeRangeFrom
            _lte: $createdTimeRangeTo
          }
          execution_id: { _is_null: false }
        }
      }
      order_by: { created_time: desc }
    ) {
      aggregate {
        count
      }
    }
  }
  ${FRAGMENT_SCHEDULED_WORK_ITEM}
`;

export const CREATE_CASE = gql`
  mutation CreateCase($insertCase: [hybris_workspace_item_insert_input!]!) {
    insert_hybris_workspace_item(objects: $insertCase) {
      returning {
        id
        summary
        job_type
        schedule_id
        schedule_type
      }
    }
  }
`;

export const GET_CASE_BY_ID = gql`
  query GetCseById($id: bigint!) {
    hybris_workspace_item(where: { id: { _eq: $id } }) {
      id
      summary
      product
      executor
      parameters
      target_nodes
      created_by
      meta_config
      hybris_workspace_item_jobs {
        job_id
        hybris_nb_cio_job {
          job_name
          is_private
          is_enabled
          is_deleted
          job_definition
        }
      }
    }
  }
`;

export const GET_RUNBOOKEXECUTOR_BY_ID = gql`
  query GetRunbookExecutor($runbook_id: String!) {
    hybris_ccv2_rb_rundeck(where: { runbook_id: { _eq: $runbook_id } }) {
      runbook_name
      runbook_id
      runbook_status
      where_to_run
      advanced_settings
    }
  }
`;

export const GET_JOB_RUN_SUMMARY = gql`
  query GetJobRunSummary($caseId: bigint!, $executionId: String) {
    hybris_workspace_item(where: { id: { _eq: $caseId } }) {
      id
      owner
      schedule_type
    }
    agent_job_log(
      where: { case_id: { _eq: $caseId }, execution_id: { _eq: $executionId } }
      limit: 1
    ) {
      execution_id
      job_log
    }
  }
`;

export const UPDATE_SCHEDULE_CASE_JOB_STATUS = gql`
  mutation UpdateScheduleCaseStatus(
    $schedule_id: String
    $schedule_params: cx_iops_job_schedule_set_input
  ) {
    update_cx_iops_job_schedule(
      where: { schedule_id: { _eq: $schedule_id } }
      _set: $schedule_params
    ) {
      returning {
        schedule_id
      }
    }
  }
`;

export const REFERENCE_DATA_TWO_TIER = gql`
  query get_refenrence_data($type_name: String, $current_time: timestamp) {
    hybris_workspace_reference_data_type(where: { type: { _eq: $type_name } }) {
      data(where: { expire_date: { _gte: $current_time } }) {
        name
        code
        alt_sequence
        default_indicator
        children(where: { expire_date: { _gte: $current_time } }) {
          name
          code
          alt_sequence
          default_indicator
        }
      }
    }
  }
`;

export const REFERENCE_DATA_THREE_TIER = gql`
  query get_refenrence_data($type_name: String, $current_time: timestamp) {
    hybris_workspace_reference_data_type(where: { type: { _eq: $type_name } }) {
      data(where: { expire_date: { _gte: $current_time } }) {
        name
        code
        alt_sequence
        default_indicator
        children(where: { expire_date: { _gte: $current_time } }) {
          name
          code
          alt_sequence
          default_indicator
          children(where: { expire_date: { _gte: $current_time } }) {
            name
            code
            alt_sequence
            default_indicator
          }
        }
      }
    }
  }
`;

export const GET_APP_LIST = gql`
  query get_all_app_list {
    hybris_workspace_application_info(where: { is_deleted: { _eq: false } }) {
      id
      name
      label
      description
      description
    }
  }
`;

export const GET_SCHEDULE_JOBS = gql`
  query getScheduleItemList(
    $app_name: String
    $status: [String!]!
    $scheduledType: String
    $owner: [String!]!
    $pageSize: Int!
    $offset: Int!
    $product: [String!]
  ) {
    listByPage: cx_iops_job_schedule(
      where: {
        status: { _in: $status }
        hybris_workspace_item: {
          app_name: { _eq: $app_name }
          schedule_type: { _eq: $scheduledType }
          owner: { _in: $owner }
          product: { _in: $product }
        }
      }
      offset: $offset
      limit: $pageSize
      order_by: { start_time: desc }
    ) {
      schedule_id
      start_time
      status
      cron_expression
      hybris_workspace_item {
        id
        schedule_type
        product
        hybris_workspace_item_jobs(limit: 1) {
          job_id
          hybris_nb_cio_job {
            job_name
          }
        }
      }
      cx_iops_job_schedule_periodic(limit: 1) {
        id
      }
    }
    list: cx_iops_job_schedule_aggregate(
      where: {
        status: { _in: $status }
        hybris_workspace_item: {
          app_name: { _eq: $app_name }
          schedule_type: { _eq: $scheduledType }
          owner: { _in: $owner }
          product: { _in: $product }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PERIODIC_JOBS = gql`
  query getPeriodicalJobs(
    $scheduleId: String!
    $pageSize: Int!
    $offset: Int!
    $ScheduledSuccess: String
    $createdTimeRangeFrom: timestamptz
    $createdTimeRangeTo: timestamptz
  ) {
    listByPage: cx_iops_job_schedule_periodic(
      where: {
        schedule_id: { _eq: $scheduleId }
        status: { _eq: $ScheduledSuccess }
        start_time: { _gte: $createdTimeRangeFrom, _lte: $createdTimeRangeTo }
      }
      offset: $offset
      limit: $pageSize
      order_by: { start_time: desc }
    ) {
      schedule_id
      service_id
      start_time
      execution_id
      status
      id
    }
    list: cx_iops_job_schedule_periodic_aggregate(
      where: {
        schedule_id: { _eq: $scheduleId }
        status: { _eq: $ScheduledSuccess }
        start_time: { _gte: $createdTimeRangeFrom, _lte: $createdTimeRangeTo }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_EXECUTION_BY_EXECUTIONIDS = gql`
  query getExecutionByExecutionIds($ExecutionIds: [String!]) {
    cx_iops_agent_job_execution(
      where: { execution_id: { _in: $ExecutionIds } }
      order_by: { execution_id: desc }
    ) {
      execution_status
      execution_id
    }
  }
`;

export const INSERT_EXECUTION_RECORD_FOR_ONDEMAND = gql`
  mutation InsertExecutionErrorRecord(
    $work_item_id: bigint
    $execution_status: String
  ) {
    insert_cx_iops_agent_job_execution(
      objects: {
        work_item_id: $work_item_id
        execution_status: $execution_status
      }
    ) {
      returning {
        work_item_id
        execution_status
      }
    }
  }
`;

export const GET_EXECUTION_RECORD_BY_EXECUTIONID = gql`
  query getExecutionRecordByExecutionId(
    $caseId: bigint!
    $executionId: String
  ) {
    cx_iops_agent_job_execution(
      where: {
        work_item_id: { _eq: $caseId }
        execution_id: { _eq: $executionId }
      }
      limit: 1
    ) {
      run_start_time
      run_end_time
      execution_status
      node_status
      file_path
    }
  }
`;
export const GET_JOB_EXECUTION_ID_BY_CASEID = gql`
  query getExecutionIdByCaseId($caseId: bigint!) {
    cx_iops_agent_job_execution(
      where: { work_item_id: { _eq: $caseId } }
      limit: 1
    ) {
      execution_id
    }
  }
`;

export const GET_CUSTOMER_SUBSCRIPTIONS_INFO_LIST = gql`
  query getCustomerSubscriptionsInfoList {
    commerce_customer_subscription {
      azure_subscription_id
      project_code
    }
  }
`;

export const GET_APP_NAME_FOR_JOB = gql`
  query getAppNameForJob($caseId: bigint!) {
    hybris_workspace_item(where: { id: { _eq: $caseId } }) {
      app_name
    }
  }
`;

export const UPDATE_WORKITEM_RECORD = gql`
  mutation updateWorkspaceItem(
    $set: hybris_workspace_item_set_input
    $id: bigint!
  ) {
    update_hybris_workspace_item(_set: $set, where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
