import * as signalR from "@microsoft/signalr";
import store from "@/store/index";

let connection: any;
let reConnection: any = null;
let socket: any;
let connected = false;
export const signalRService = {
  /**
   * create a new instance of signalR
   * @returns the instance of signalR
   */
  initSignalR(): any {
    const url = process.env.VUE_APP_SIGNALR || "";
    if (!connection) {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: async () => {
            return await this.getToken();
          },
        })
        .withAutomaticReconnect()
        .build();
    }
    return connection;
  },

  /**
   * Build the connection of signalR
   * @returns void
   */
  connectToSignalR(): void {
    const connection = this.initSignalR();
    connection
      .start()
      .then(() => {
        if (reConnection) {
          clearInterval(reConnection);
          reConnection = null;
        }
      })
      .catch(() => {
        console.log("Could not connect to signalR.");
      });

    connection.onclose(() => {
      if (!reConnection) {
        reConnection = setInterval(() => {
          this.connectToSignalR();
        }, 5000);
      }
    });
    connection.onreconnecting((error) => {
      console.log(`Connection lost due to error ${error}. Reconnecting...`);
    });
  },

  /**
   * start to monitor the signalR targets
   * @param {string} workItemId: the id of current work item
   * @param {string} targetName: the target to be monitored
   * @param {Function} handleWebsocketData: callback function
   * @param {string} serviceId:the serviceId of an job execution (service_id in table cx_iops_job_schedule_periodic)
   * @returns
   */
  minitorSignalRTarget(
    workItemId: string | (string | null)[],
    serviceId: string | (string | null)[],
    targetName: string,
    handleWebsocketData?: (...args: any[]) => void
  ): void {
    if (!connection) {
      return;
    }
    const workItemIds = Array.isArray(workItemId) ? workItemId : [workItemId];
    const serviceIds = Array.isArray(serviceId) ? serviceId : [serviceId];

    for (let i = 0; i < workItemIds.length; i++) {
      connection.off(
        store.state.userId +
          "_" +
          workItemIds[i] +
          "_" +
          (serviceIds[i] || "noServiceId") +
          "_" +
          targetName
      );
      connection.on(
        store.state.userId +
          "_" +
          workItemIds[i] +
          "_" +
          (serviceIds[i] || "noServiceId") +
          "_" +
          targetName,
        (data) => {
          handleWebsocketData && handleWebsocketData(data);
        }
      );
    }
  },

  /**
   * Stop the signalR connection
   * @returns void
   */
  stopConnect(): void {
    const connection = this.initSignalR();
    connection.stop();
  },

  /**
   * Get bearerToken from localstorage
   * if the bearerToken expired, get new token by tokenGrant API
   * @returns string
   */
  getToken(): any {
    const token = localStorage.getItem("auth_jwt_decoded") || "";
    const token_exp = JSON.parse(token).exp;
    const currentTime = new Date().getTime();
    let tempToken = "";
    if (token_exp && token_exp * 1000 > currentTime) {
      tempToken = localStorage.getItem("auth_jwt") || "";
    } else {
      location.reload();
    }
    return tempToken;
  },

  initWs(): void {
    if (connected) return;
    socket = new WebSocket(
      `${process.env.VUE_APP_DATA_STREAM_WS}?subscription-key=${process.env.VUE_APP_APIM_KEY}`
    );

    // socket = new WebSocket("ws://localhost:3000");

    socket.onopen = () => {
      connected = true;
      console.log("WebSocket is connected");

      //set a sential channel to keep socket alive
      socket.send(
        JSON.stringify({
          type: "subscribe",
          channel: `iOps_workspace_sentinel`,
        })
      );
    };
    socket.onclose = () => {
      connected = false;
      console.log("WebSocket disconnected, retry...");
      setTimeout(() => {
        this.initWs();
      }, 5000);
    };
    socket.onerror = (error) => {
      console.error("WebSocket error: " + error);
    };
  },

  monitorWsChannel(
    caseId: string | (string | null)[],
    executionId: string | (string | null)[],
    targetName: string,
    handleWebsocketData?: (...args: any[]) => void
  ): void {
    console.log(
      "print",
      `${caseId}_${executionId || "UnKnown"}_${targetName}`,
      connected
    );
    if (!connected) {
      return;
    }
    const caseIds = Array.isArray(caseId) ? caseId : [caseId];
    const executionIds = Array.isArray(executionId)
      ? executionId
      : [executionId];

    for (let i = 0; i < caseIds.length; i++) {
      socket.send(
        JSON.stringify({
          type: "unsubscribe",
          channel: `${caseIds[i]}_${
            executionIds[i] || "UnKnown"
          }_${targetName}`,
        })
      );

      socket.send(
        JSON.stringify({
          type: "subscribe",
          channel: `${caseIds[i]}_${
            executionIds[i] || "UnKnown"
          }_${targetName}`,
        })
      );
    }

    socket.onmessage = (event) => {
      console.log("WebSocket message received: " + event.data);
      handleWebsocketData && handleWebsocketData(event.data);
    };
  },
};
