import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentApp: {
      id: 0,
      name: "",
      label: "",
      description: "",
      alt_sequence: 10,
      is_deleted: false,
    },
    appList: [],
    userId: "",
    userName: "",
    userEmail: "",
    isRefetchingScheduleList: false,
    userRoles: [],
  },
  mutations: {
    setCurrentApp(state, appObj) {
      state.currentApp = appObj;
      localStorage.setItem("currentApp", JSON.stringify(appObj));
    },
    setAppList(state, appList) {
      state.appList = appList;
    },
    setUserProfile(state) {
      const auth_jwt_decoded = localStorage.getItem("auth_jwt_decoded") || "";
      const json_obj = JSON.parse(auth_jwt_decoded);
      state.userId = json_obj.sub || "";
      state.userEmail = json_obj.mail || "";
      state.userName = json_obj.displayName || "";
      state.userRoles = json_obj.groups || [];
    },
    setRefetchingScheduleList(state, status) {
      state.isRefetchingScheduleList = status;
    },
  },
  getters: {},
  actions: {},
  modules: {},
});
